<template>
  <div>
    <section id="knowledge-base-content">
      <div class="group-area mt-1">
        <div class="row">
          <div class="col-lg-6 col-xs-12text-lg-start">
            <h2>
              <span style="color: #212529;">TENTANG</span><span style="color: #2e72b9;">&nbsp;#KITA</span><span style="color: #eec82a;">KERJA</span>
            </h2>
            <h3>
              Di sini, anda dapat melihat beragam informasi dan terlibat dalam berbagai aksi dari Yayasan Plan International Indonesia.
            </h3>
            <p>KitaKerja (https://kitakerja.id) merupakan platform yang diperuntukkan bagi kaum muda untuk peningkatan keterampilan mereka sehingga memudahkan kaum muda mendapatkan pekerjaan (employment) atau berwirausaha (entrepreneurship).</p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-xs-12" v-if="showAll()">
            <b-img :src="require('@/assets/images/concept-2-1.png')" class="img-fluid" />
          </div>
          <div class="col-lg-8 col-xs-12 text-lg-start">
            <h2>Layanan Yang Tersedia</h2>
            <p>
              <!-- Berikut ini beberapa daftar layanan yang tersedia di aplikasi #KitaKerja. -->
              Sebagai suatu platform untuk employment dan entrepreneurship, maka aplikasi ini menyediakan berbagai layanan berikut ini
            </p>
            <b-carousel
              id="carousel-1"
              :interval="10000"
              indicators
              img-height="1080"
              v-if="showSlider()"
            >
              <b-carousel-slide
                v-for="item in serviceList"
                :key="item.id"
              >
                <template #img>
                  <b-card
                    class="text-center cursor-pointer"
                    :img-src="item.image"
                    img-top
                    @click="select(item)"
                  >
                    <h4>{{ item.title }}</h4>
                    <b-card-text class="mt-1">
                      {{ item.desc }}
                    </b-card-text>
                  </b-card>
                  <br/>
                </template>
              </b-carousel-slide>
            </b-carousel>
            <!-- content -->
            <b-row
              class="kb-search-content-info match-height"
              v-if="showAll()"
            >
              <b-col
                v-for="item in serviceList"
                :key="item.id"
                md="6"
                sm="6"
                class="kb-search-content"
              >
                <b-row class="mb-1">
                  <b-col
                    md="3"
                    sm="3"
                  >
                    <b-avatar
                      ref="previewEl"
                      :src="item.image"
                      size="50px"
                      circle
                      @click="select(item)"
                    />
                  </b-col>
                  <b-col
                    md="9"
                    sm="9"
                  >
                    <h3 style="color:#EEC82A">{{ item.title }}</h3>
                    <p>{{ item.deskripsi }}</p>
                    <b-link
                      class="btn btn-primary"
                      @click="select(item)"
                    >
                      Akses Sekarang
                    </b-link>
                  </b-col>
                </b-row>
                <!-- <b-card
                  class="text-center cursor-pointer"
                  :img-src="item.image"
                  img-top
                  @click="select(item)"
                >
                  <h4>{{ item.title }}</h4>
                  <b-card-text class="mt-1">
                    {{ item.desc }}
                  </b-card-text>
                </b-card> -->
              </b-col>
            </b-row>
          </div>
        </div>
        <hr>
      </div>
    </section>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect'
import {
  BRow, BCol, BCard, BImg, BAvatar, BLink, BCardBody, BForm, BInputGroup, BFormInput, BCardText, BInputGroupPrepend, BCarousel, BCarouselSlide,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BImg,
    BAvatar,
    BLink,
    // BForm,
    // BInputGroup,
    // BInputGroupPrepend,
    // BFormInput,
    BCarousel,
    BCarouselSlide,
  },
  data() {
    return {
      knowledgeBaseSearchQuery: '',
      kb: [],
      showAllData: true,
    }
  },
  computed: {
    // filteredKB() {
    // const knowledgeBaseSearchQueryLower = this.knowledgeBaseSearchQuery.toLowerCase()
    // return this.kb.filter(item => item.title.toLowerCase().includes(knowledgeBaseSearchQueryLower) || item.desc.toLowerCase().includes(knowledgeBaseSearchQueryLower))
    // },
  },
  props: {
    serviceList: {
      type: Array,
      required: true,
    },
    amres: {
      type: String,
      required: true,
    },
  },
  mounted() {
    if (isMobile) {
      this.showAllData = false
    }
  },
  methods: {
    showSlider() {
      return isMobile
    },
    showAll() {
      let returns = true
      if (!isMobile && this.showAllData === true) {
        returns = true
      } else {
        returns = false
      }
      return returns
    },
    showMsgBoxAmres() {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxOk('Mohon melengkapi Data Pengguna terlebih dahulu.', {
          title: 'Informasi Kelengkapan',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Tutup',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          this.boxTwo = value
        })
    },
    select(data) {
      // if (this.amres === true) {
      const a = document.createElement('a')
      a.target = '_blank'
      a.href = data.url
      a.click()
      // } else {
      //   this.showMsgBoxAmres()
      // }
      // window.location.href = data.url
    },
  },
  created() {
    // this.$http.get('/kb/data/knowledge_base').then(res => { this.kb = res.data })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
</style>
