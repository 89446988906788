<template>
  <content class="blog-wrapper">
    <div class="group-area mt-1">
      <h4>Proyek</h4>
      <p>
        Ini adalah Proyek dalam membangun kaum muda Indonesia!!
      </p>
      <hr>
    </div>
    <!-- blogs -->
    <content-loader
      v-if="loaderShow"
    />
    <b-carousel
      id="carousel-1"
      :interval="10000"
      indicators
      img-height="1080"
      v-if="showSlider()"
    >
      <b-carousel-slide
        v-for="item in projectList"
        :key="item.id"
      >
        <template #img>
          <b-card
            tag="article"
            no-body
          >
            <b-link :to="{ name: 'pages-projek-detail', params: { id: item.id } }">
              <b-img
                :src="item.image"
                class="card-img-top"
              />
            </b-link>
            <b-card-body>
              <b-card-title>
                <b-link
                  :to="{ name: 'pages-projek-detail', params: { id: item.id } }"
                  class="blog-title-truncate text-body-heading"
                >
                  {{ item.nama }}
                </b-link>
              </b-card-title>
              <b-media no-body>
                <b-media-aside
                  vertical-align="center"
                  class="mr-50"
                >
                  <b-avatar
                    href="javascript:void(0)"
                    size="24"
                    :src="item.avatar"
                  />
                </b-media-aside>
                <b-media-body>
                  <small class="text-muted mr-50">by</small>
                  <small>
                    <b-link
                      :to="{ name: 'pages-profile-detail', params: {id: item.created_by}}"
                      class="text-body"
                    >
                      {{ item.created_by_user.name }}
                    </b-link>
                  </small>
                  <span class="text-muted ml-75 mr-50">|</span>
                  <small class="text-muted">{{ item.datePost }}</small>
                </b-media-body>
              </b-media>
              <hr>
              <div class="d-flex justify-content-between align-items-center">
                <b-link :to="{ path: `/kegiatan/list?proyekId=${item.id}`}">
                  <div class="d-flex align-items-center text-body">
                    <feather-icon
                      icon="ActivityIcon"
                      class="mr-50"
                    />
                    <span class="font-weight-bold">{{ kFormatter(item.totalKegiatan) }}  Kegiatan</span>
                  </div>
                </b-link>
                <b-link
                  :to="{ name: 'pages-projek-detail', params: { id: item.id } }"
                  class="font-weight-bold"
                >
                  Lihat Selengkapnya
                </b-link>
              </div>
            </b-card-body>
          </b-card>
          <br/>
        </template>
      </b-carousel-slide>
    </b-carousel>
    <b-row class="blog-list-wrapper"
      v-if="showAll()"
    >
      <b-col
        v-for="item in projectList"
        :key="item.id"
        md="4"
      >
        <b-card
          tag="article"
          no-body
        >
          <b-link :to="{ name: 'pages-projek-detail', params: { id: item.id } }">
            <b-img
              :src="item.image"
              class="card-img-top"
            />
          </b-link>
          <b-card-body>
            <b-card-title>
              <b-link
                :to="{ name: 'pages-projek-detail', params: { id: item.id } }"
                class="blog-title-truncate text-body-heading"
              >
                {{ item.nama }}
              </b-link>
            </b-card-title>
            <b-media no-body>
              <b-media-aside
                vertical-align="center"
                class="mr-50"
              >
                <b-avatar
                  href="javascript:void(0)"
                  size="24"
                  :src="item.avatar"
                />
              </b-media-aside>
              <b-media-body>
                <small class="text-muted mr-50">by</small>
                <small>
                  <b-link
                    :to="{ name: 'pages-profile-detail', params: {id: item.created_by}}"
                    class="text-body"
                  >
                    {{ item.created_by_user.name }}
                  </b-link>
                </small>
                <span class="text-muted ml-75 mr-50">|</span>
                <small class="text-muted">{{ item.datePost }}</small>
              </b-media-body>
            </b-media>
            <hr>
            <div class="d-flex justify-content-between align-items-center">
              <b-link :to="{ path: `/kegiatan/list?proyekId=${item.id}`}">
                <div class="d-flex align-items-center text-body">
                  <feather-icon
                    icon="ActivityIcon"
                    class="mr-50"
                  />
                  <span class="font-weight-bold">{{ kFormatter(item.totalKegiatan) }}  Kegiatan</span>
                </div>
              </b-link>
              <b-link
                :to="{ name: 'pages-projek-detail', params: { id: item.id } }"
                class="font-weight-bold"
              >
                Lihat Selengkapnya
              </b-link>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <!-- pagination -->
      <b-col cols="12"
        v-if="rows"
      >
        <div class="my-2">
          <b-pagination
            v-model="currentPage"
            align="center"
            :total-rows="rows"
            :per-page="perPage"
            first-number
            last-number
            @change="handlePageChange"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-col>
      <b-col cols="12"
        v-else
      >
        <b-alert variant="secondary" show>
          <h4 class="alert-heading">
            Belum ada Proyek
          </h4>
          <div class="alert-body">
            Anda belum terhubung dengan Proyek, Segera bergabung dengan Proyek dengan cara "Join" di Proyek
          </div>
        </b-alert>
      </b-col>
    </b-row>

    <list-kegiatan
      :onlyView="true"
    />

    <!-- blogs -->
    <!-- <b-row class="blog-list-wrapper">
      <b-col
        v-for="item in kegiatanList"
        :key="item.id"
        md="4"
      >
        <meetup
          :dataMeetup="item"
        />
      </b-col>
    </b-row> -->

    <!--/ blogs -->
    <div class="group-area mt-1">
      <h4>Pelatihan</h4>
      <p>
        Berikut ini beberapa pelatihan yang Anda ikuti.
      </p>
      <hr>
    </div>
    <b-card>
      <list-aktivitas />
      <!-- <b-table
        responsive="sm"
        :items="items"
      >
        <template #cell(link)="data">
          <b-button
            @click="select(data.item.link)"
            size="sm"
            class="mr-2"
          >
            Lihat Selengkapnya
          </b-button>
        </template>
      </b-table> -->
    </b-card>
    <!-- <b-card-code
      v-else
    >
      <b-alert variant="secondary" show>
        <h4 class="alert-heading">
          Belum ada Riwayat Kegiatan
        </h4>
        <div class="alert-body">
          Anda belum memiliki riwayat kegiatan yang ada di #KitaKerja.
        </div>
      </b-alert>
    </b-card-code> -->

  </content>
</template>

<script>
import { isMobile } from 'mobile-device-detect'
import {
  BRow, BCol, BCard, BAlert, BFormInput, BTable, BCardText, BButton, BCardTitle, BMedia, BAvatar, BMediaAside, BMediaBody, BImg, BCardBody, BLink, BBadge, BFormGroup, BInputGroup, BInputGroupAppend, BPagination, BCarousel, BCarouselSlide,
} from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'
// import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  getAllProyek,
} from '@/api/proyek'
import {
  getDashboard,
} from '@/connection-api/dashboard'
import ContentLoader from '@/views/layouts/Loader.vue'
import ListKegiatan from '../../kegiatan/list/ListDashboard.vue'
import ListAktivitas from '../../aktivitas/list/List.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    // BTable,
    BAlert,
    // BFormInput,
    // BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    // BLink,
    // BBadge,
    // BFormGroup,
    // BInputGroup,
    // BInputGroupAppend,
    // BButton,
    BImg,
    BLink,
    BPagination,
    // ContentWithSidebar,
    // Meetup,
    ListAktivitas,
    ListKegiatan,
    ContentLoader,
    BCarousel,
    BCarouselSlide,
  },
  data() {
    return {
      search_query: '',
      blogList: [],
      projectList: [],
      courseList: [],
      kegiatanList: [],
      blogSidebar: {},
      currentPage: 1,
      perPage: 9,
      rows: 0,
      userData: JSON.parse(localStorage.getItem('userData')),
      loaderShow: true,
      items: [],
      amres: localStorage.getItem('amres'),
      // codeBasic,
      showAllData: true,
    }
  },
  created() {
    // this.$http.get('/blog/list/kegiatan').then(res => { this.kegiatanList = res.data })
    // this.$http.get('/blog/list/project').then(res => { this.projectList = res.data })
    // this.$http.get('/blog/list/data').then(res => { this.blogList = res.data })
    // this.$http.get('/blog/list/course').then(res => { this.courseList = res.data })
    // this.$http.get('/blog/list/data/sidebar').then(res => { this.blogSidebar = res.data })
  },
  async mounted() {
    if (isMobile) {
      this.showAllData = false
    }
    try {
      this.show = true
      this.currentPage = 1
      this.getDataPage()
      this.show = false
      this.loaderShow = false
      // const response = await getDashboard()
      // this.loaderShow = false
      // this.items = response.data.data.currents
    } catch (error) {
      // console.log(error.response.data)
    }
  },
  methods: {
    showSlider() {
      return isMobile
    },
    showAll() {
      let returns = true
      if (!isMobile && this.showAllData === true) {
        returns = true
      } else {
        returns = false
      }
      return returns
    },
    select(url) {
      const a = document.createElement('a')
      a.target = '_blank'
      a.href = url
      a.click()
    },
    handlePageChange(value) {
      this.currentPage = value
      this.getDataPage()
    },
    async getDataPage() {
      const params = {
        page: this.currentPage,
        user_id: this.userData.id,
      }
      const response = await getAllProyek(params)
      this.projectList = response.data.data
      this.rows = response.data.total
    },
    kFormatter,
    tagsColor(tag) {
      if (tag === 'Quote') return 'light-info'
      if (tag === 'Gaming') return 'light-danger'
      if (tag === 'Fashion') return 'light-primary'
      if (tag === 'Video') return 'light-warning'
      if (tag === 'Food') return 'light-success'
      return 'light-primary'
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
