<template>
  <content class="blog-wrapper">
    <b-overlay
      :show="show"
      no-wrap
    />
    <div class="group-area mt-1">
      <h4>Daftar Kegiatan</h4>
      <p>
        Berikut ini beberapa kegiatan yang saya ikuti di aplikasi #KitaKerja.
      </p>
    </div>

    <content-loader
      v-if="loaderShow"
    />
    <!--/ sidebar -->
    <b-row class="blog-list-wrapper mt-1"
      v-if="showAll()"
    >
      <b-col
        v-for="data in kegiatanList.data"
        :key="data.id"
        md="4"
      >
        <meetup
          :dataMeetup="data"
          :onlyView="onlyView"
        />
      </b-col>
    </b-row>
    <b-carousel
      id="carousel-1"
      :interval="10000"
      indicators
      img-height="1080"
      v-if="showSlider()"
    >
      <b-carousel-slide
        v-for="data in kegiatanList.data"
        :key="data.id"
      >
        <template #img>
          <meetup
            :dataMeetup="data"
            :onlyView="false"
          />
          <br/>
        </template>
      </b-carousel-slide>
    </b-carousel>
    <b-row>
      <!-- pagination -->
      <b-col cols="12"
        v-if="rows"
      >
        <div class="my-2">
          <b-pagination
            v-model="currentPage"
            align="center"
            :total-rows="rows"
            :per-page="perPage"
            @change="handlePageChange"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-col>
      <b-col cols="12"
        v-else
      >
        <b-alert variant="secondary" show>
          <h4 class="alert-heading">
            Belum ada Daftar Kegiatan
          </h4>
          <div class="alert-body">
            Anda belum mengikuti kegiatan yang ada di #KitaKerja, Segera bergabung dengan kegiatan kami dengan cara "Join" di menu Kegiatan.
          </div>
        </b-alert>
      </b-col>
    </b-row>
  </content>
</template>

<script>
import { isMobile } from 'mobile-device-detect'
import {
  BRow, BCol, BCard, BFormInput, BAlert, BButton, BCardText, BCardTitle, BMedia, BAvatar, BMediaAside, BMediaBody, BImg, BCardBody, BLink, BBadge, BFormGroup, BInputGroup, BInputGroupAppend, BPagination, BOverlay, BCarousel, BCarouselSlide,
} from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'
import {
  getAllKegiatanByUser,
} from '@/api/kegiatan'
import Meetup from './Meetup.vue'
import ContentLoader from '../../layouts/Loader.vue'

export default {
  components: {
    BRow,
    BCol,
    // BButton,
    BAlert,
    BPagination,
    Meetup,
    BOverlay,
    ContentLoader,
    BCarousel,
    BCarouselSlide,
  },
  props: ['onlyView'],
  data() {
    return {
      show: false,
      search_query: '',
      blogList: [],
      projectList: [],
      courseList: [],
      kegiatanList: [],
      blogSidebar: {},
      currentPage: 1,
      perPage: 9,
      userData: JSON.parse(localStorage.getItem('userData')),
      rows: 0,
      /* eslint-disable global-require */
      defaultImage: require('@/assets/images/pages/default.png'),
      /* eslint-disable global-require */
      loaderShow: true,
      searchForm: {
        nama: '',
        private: {
          id: 3,
          label: 'Semua',
        },
        status: {
          id: 'aktif',
          label: 'Aktif',
        },
        only_me: false,
      },
      showAllData: true,
    }
  },
  created() {
    // this.$http.get('/blog/list/data').then(res => { this.blogList = res.data })
    // this.$http.get('/blog/list/project').then(res => { this.projectList = res.data })
    // this.$http.get('/blog/list/course').then(res => { this.courseList = res.data })
    // this.$http.get('/blog/list/data/sidebar').then(res => { this.blogSidebar = res.data })
  },
  methods: {
    showSlider() {
      return isMobile
    },
    showAll() {
      let returns = true
      if (!isMobile && this.showAllData === true) {
        returns = true
      } else {
        returns = false
      }
      return returns
    },
    handlePageChange(value) {
      this.currentPage = value
      this.getDataPage()
    },
    async getDataPage() {
      const response = await getAllKegiatanByUser(this.currentPage, this.userData.id)
      this.kegiatanList = response.data
      this.kegiatanList.data.forEach((value, index) => {
        if (value.img) {
          this.kegiatanList.data[index].img = `${process.env.VUE_APP_API_URL}/kegiatan/download/${value.img}`
        } else {
          this.kegiatanList.data[index].img = this.defaultImage
        }
      })
      this.rows = response.data.total
      this.loaderShow = false
    },
    tambah() {
      this.$router.push('/kegiatan/tambah')
    },
    kFormatter,
    tagsColor(tag) {
      if (tag === 'Quote') return 'light-info'
      if (tag === 'Gaming') return 'light-danger'
      if (tag === 'Fashion') return 'light-primary'
      if (tag === 'Video') return 'light-warning'
      if (tag === 'Food') return 'light-success'
      return 'light-success'
    },
  },
  async mounted() {
    if (isMobile) {
      this.showAllData = false
    }
    try {
      this.show = true
      this.getDataPage()
      this.show = false
    } catch (error) {
      // console.log(error.response.data)
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
